import {Country} from '@utils/constants/localisation';
import React, {createContext, useCallback, useState} from 'react';


export enum UserFormStates {
    driverDetails = 'driverDetails',
    carInfo = 'carInfo',
    personalInfo = 'personalInfo',
    addressInfo = 'addressInfo',
    emergencyContactInfo = 'emergencyContactInfo',
    contractDetails = 'contractDetails',
    usedVehicleAddendum = 'usedVehicleAddendum',
    additionalDriver = 'additionalDriver',
    cancellationDetails = 'cancellationDetails',
    testDriveDetails = 'testDriveDetails',
    splendInitiatives = 'splendInitiatives',
    uberCAPDetails = 'uberCAPDetails'
};

type FormState = {
    driverDetails: DriverDetails;
    carInfo: CarInfo;
    personalInfo: PersonalInfo;
    addressInfo: AddressInfo;
    emergencyContactInfo: EmergencyContactInfo;
    contractDetails: ContractDetails;
    usedVehicleAddendum: UsedVehicleAddendumDetails;
    additionalDriver: AdditionalDriver;
    cancellationDetails: CancellationDetails;
    testDriveDetails: TestDriveDetails;
    splendInitiatives: SplendInitiatives;
    uberCAPDetails: UberCAPDetails;
};

type UberCAPDetails = {
    plan:string;
    totalSavings:number;
    schedule:string;
}

type AdditionalDriver = {
    subsID: string;
    plan: string;
    firstName: string;
    lastName:  string;
    licenceNumber: string;
    licenceState: string;
    expiryDate: string;
    dob: string;
};

type DriverDetails = {
    crmId: string;
    blacklisted: boolean;
    tag: string;
    plan: string;
    province: string;
    driversLicenceNumber: string;
    dateOfBirth: string;
    driversLicenceExpiry: string;
    licenceCountry: string;
    country: Country;
    activePlanState: string;
    pcoLicenseNumber: string;
    pcoLicenceExpiryDate: string;
};

type CarInfo = {
    id: string;
    make: string;
    model: string;
    year: number;
    colour: string;
    registrationPlate: string;
    vin: string;
    bodyType: string;
    odometer: number;
    hub: string;
    vehicleId: string;
};

type PersonalInfo = {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    alternativeName: string;
    nationality: string;
    hirerABN: string;
    companyName: string;
    companyABN: string;
    tollTagIssuer: string;
    tollTagAccountNumber: string;
    tollTagNumber: string;
    gender: string;
    mobile: string;
    nationalInsuranceNumber: string;
    employmentStatus: string;
};

type AddressInfo = {
    street: string;
    suburb: string;
    city: string;
    state: string;
    postcode: string;
    timeAtAddressYears: number;
    timeAtAddressMonths: number;
};

type EmergencyContactInfo = {
    primaryName: string;
    primaryAddress: string;
    primaryPhone: string;
    primaryRelationship: string;
    secondaryName: string;
    secondaryAddress: string;
    secondaryPhone: string;
    secondaryRelationship: string;
};

type ContractDetails = {
    executionDate: string;
    subsStartDate: string;
    subsEndDate: string;
    planLen: number;
    planId: string;
    planName: string;
    planWeeklyFee: number;
    planSetupFee: string | null;
    planMinimumPeriod: number;
    planMinimumDailyPeriod: number;
    planWeeklyDistAllowance: number;
    planAdditionalDistCharge: number;
    planDailyFee: number | null;
    additionalDriverFee: string;
    insuranceCover: string;
    contactOwner: string;
    contactOwnerId: string;
    contactOwnerEmail: string;
    weeklySubWithoutIncentive: number;
    incentiveDiscount: number;
    weeklySubWithIncentive:number;
    hasNSWAddendum: boolean;
    subscriptionId: string;
};

type UsedVehicleAddendumDetails = {
    reducedSubscriptionTerm: number;
    numberOfDiscountedWeeks: number;
    scheduledIntervalServices: number;
    replacementTyres: number;
    replacementBrakePads: number;
    replacementOrMachined: number;
}

type TestDriveDetails = {
    timeOut: string;
    timeBack: string;
    potentialId: string;
    vehicle: string;
    contactId: string;
    fleetioUrl: string;
    zohoUrl: string;
}

type CancellationDetails = {
    endingKms: string;
    fleetioStatusId: string,
    fleetioStatusName: string,
    noticeGiven: string | null;
    cancellationPlanType: string;
    weeklySubscriptionFee: number;
    dailySubscriptionFee: number;
    daysForNoticePeriodBreach: number;
    daysForMinimumPeriodBreach: number;
    noticePeriodCharge: number;
    minimumPeriodCharge: number;
    totalEarlyCancellationCharges: number;
    totalExtraCharges: number;
    totalAncillaryCharges: number;
    totalAcceptanceRemarketingFee: number;
    addonQuantity: number;
    addonPrice: number;
    addonId: string;

    cancellationType: string;
    cancellationCategory: string;
    cancellationSubCategory: string;

    customerReturnDate: string;
    cancellationAddRestrictionToAccount: string;

    cancellationAdditionalInfo: string;

    cancellationDate: string;
    totalCancellationCharges: number;
    contactId: string;
    addons: Array<any>;
    acceptanceAndRemarketing: number;
    fleetioUrl: string;
    zohoUrl: string;
    chargebeeUrl: string;
    vehicleDepreciationFee: number;
    hub: string;
    phCharges: number;
}

type SplendInitiatives = {
    weeklyDistanceAllowance: string;
    weeklyDistanceAllowanceDiscount: number,
    minimumPeriodWeeks: string;
    minimumPeriodWeeksDiscount: number,
    splendInitiative: string | null;
    tripCount: string,
    tripDiscount: number;
    vehicleUtilisation: string,
    availableVehicleDiscount: number;
    totalDynamicPricing: number;
    adjustedWeeklyHireFee: number;
    ignitionEndDate: string;
    driverRating: string;
    additionalDiscount: number
}

type formTypes = Partial<DriverDetails> | Partial<CarInfo> | Partial<PersonalInfo> | Partial<AddressInfo> 
| Partial<EmergencyContactInfo> | Partial<ContractDetails> | Partial<UsedVehicleAddendumDetails> | Partial <AdditionalDriver>
| Partial<CancellationDetails> | Partial<TestDriveDetails> | Partial<SplendInitiatives> | Partial<UberCAPDetails>

type UserJourneyValue = {
    // FormState has been wrapped with Partial so individual groups (Personal Info, 
    //Car info etc)of the form can be managed from different pages throughout the RAV Journey
    formState: FormState;
    updateFormState: (
        stateName: UserFormStates,
        updatedFormState: formTypes,
    ) => void;
    resetFormState: (stateName: UserFormStates) => void;
};

const UserJourney = createContext<UserJourneyValue | undefined>(undefined);

export const useUserJourneyContext = () => {
    const context = React.useContext(UserJourney);
    if (!context) {
        throw new Error('useUserJourney must be used within a UserJourneyProvider');
    }
    return context;
};

interface IUserJourneyCtxProvider {
    children: React.ReactNode
};

const additionalDriverInit: AdditionalDriver = {
    subsID: '',
    plan: '',
    firstName: '',
    lastName:  '',
    licenceNumber: '',
    licenceState: '',
    expiryDate: '',
    dob: '',
}

const driverDetailsInit: DriverDetails = {
    blacklisted: true,
    crmId: '',
    tag: '',
    plan: '',
    province: '',
    driversLicenceNumber: '',
    dateOfBirth: '',
    driversLicenceExpiry: '',
    licenceCountry: '',  // todo fill with gb
    country: '' as Country,
    activePlanState: '',
    pcoLicenseNumber: '',
    pcoLicenceExpiryDate: '',
};

const carInfoInit: CarInfo = {
    id: '',
    make: '',
    model: '',
    year: 0,
    colour: '',
    registrationPlate: '',
    vin: '',
    bodyType: '',
    odometer: 0,
    hub: '',
    vehicleId: ''
};

const personalInfoInit: PersonalInfo = {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    alternativeName: '',
    nationality: '',
    hirerABN: '',
    companyName: '',
    companyABN: '',
    tollTagIssuer: '',
    tollTagAccountNumber: '',
    tollTagNumber: '',
    gender: '',
    mobile: '',
    nationalInsuranceNumber: '',
    employmentStatus: '',
};

const addressInfoInit: AddressInfo = {
    street: '',
    suburb: '',
    city: '',
    state: '',
    postcode: '',
    timeAtAddressYears: 0,
    timeAtAddressMonths: 0,
}

const emergencyContactInfoInit: EmergencyContactInfo = {
    primaryName: '',
    primaryAddress: '',
    primaryPhone: '',
    primaryRelationship: '',
    secondaryName: '',
    secondaryAddress: '',
    secondaryPhone: '',
    secondaryRelationship: '',
}

const contractDetailsInit: ContractDetails = {
    executionDate: '',
    subsStartDate: '',
    subsEndDate: '',
    planLen: 0,
    planId: '',
    planName: '',
    planWeeklyFee: 0,
    planSetupFee: null,
    planMinimumPeriod: 0,
    planMinimumDailyPeriod: 0,
    planWeeklyDistAllowance: 0,
    planAdditionalDistCharge: 0,
    additionalDriverFee: '0',
    insuranceCover: '',
    contactOwner: '',
    contactOwnerId: '',
    contactOwnerEmail: '',
    weeklySubWithoutIncentive: 0,
    incentiveDiscount: 0,
    weeklySubWithIncentive: 0,
    hasNSWAddendum: false,
    planDailyFee: 0,
    subscriptionId: '',
    
};

const usedVehicleAddendumInit = {
    reducedSubscriptionTerm: 0,
    numberOfDiscountedWeeks: 0,
    scheduledIntervalServices: 0,
    replacementTyres: 0,
    replacementBrakePads: 0,
    replacementOrMachined: 0,
}

const cancellationDetailsInit = {
    endingKms: '',
    fleetioStatusId: '',
    fleetioStatusName: '',
    noticeGiven: '',
    cancellationPlanType: '',
    weeklySubscriptionFee: 0,
    dailySubscriptionFee: 0,
    daysForNoticePeriodBreach: 0,
    daysForMinimumPeriodBreach: 0,
    noticePeriodCharge: 0,
    minimumPeriodCharge: 0,
    totalEarlyCancellationCharges: 0,
    totalExtraCharges: 0,
    totalAncillaryCharges: 0,
    totalAcceptanceRemarketingFee: 0,
    addonId: '',
    addonQuantity: 0,
    addonPrice: 0,

    cancellationType: '',
    cancellationCategory: '',
    cancellationSubCategory: '',


    customerReturnDate: '',
    cancellationAddRestrictionToAccount: '',

    cancellationAdditionalInfo: '',

    cancellationDate: '',
    totalCancellationCharges: 0,
    contactId: '',
    addons: [],
    acceptanceAndRemarketing: 0,
    fleetioUrl: '',
    zohoUrl: '',
    chargebeeUrl: '',
    vehicleDepreciationFee: 0,
    hub: '',
    phCharges: 0,
}

const testDriveDetailsInit = {
    timeOut: '',
    timeBack: '',
    potentialId: '',
    vehicle: '',
    contactId: '',
    fleetioUrl: '',
    zohoUrl: '',
}

const splendInitiativesInit = {
    weeklyDistanceAllowance: '',
    weeklyDistanceAllowanceDiscount: 0,
    minimumPeriodWeeks: '',
    minimumPeriodWeeksDiscount: 0,
    splendInitiative: null,
    tripCount: '',
    tripDiscount: 0,
    vehicleUtilisation: '',
    availableVehicleDiscount: 0,
    totalDynamicPricing: 0,
    adjustedWeeklyHireFee: 0,
    ignitionEndDate: '',
    driverRating: '',
    additionalDiscount: 0
}

const uberCAPDetailsInit = {
    plan: '',
    totalSavings: 0,
    schedule: ''
}


export const UserJourneyProvider: React.FC<IUserJourneyCtxProvider> = ({ children }: IUserJourneyCtxProvider) => {

    const [driverDetails, setDriverDetails] = useState<DriverDetails>(driverDetailsInit);
    const [carInfo, setCarInfo] = useState<CarInfo>(carInfoInit);
    const [personalInfo, setPersonalInfo] = useState<PersonalInfo>(personalInfoInit);
    const [addressInfo, setAddressInfo] = useState<AddressInfo>(addressInfoInit);
    const [emergencyContactInfo, setEmergencyContactInfo] = useState<EmergencyContactInfo>(emergencyContactInfoInit);
    const [contractDetails, setContractDetails] = useState<ContractDetails>(contractDetailsInit);
    const [usedVehicleAddendum, setUsedVehicleAddenumInfo] = useState<UsedVehicleAddendumDetails>(usedVehicleAddendumInit);
    const [additionalDriver, setAdditionalDriver] = useState<AdditionalDriver> (additionalDriverInit);
    const [cancellationDetails, setCancellationDetails] = useState<CancellationDetails> (cancellationDetailsInit);
    const [testDriveDetails, setTestDriveDetails] = useState<TestDriveDetails> (testDriveDetailsInit);
    const [splendInitiatives, setSplendInitiatives] = useState<SplendInitiatives> (splendInitiativesInit);
    const [uberCAPDetails, setUberCAPDetails] = useState<UberCAPDetails> (uberCAPDetailsInit);



    const formState = {
        driverDetails,
        carInfo,
        personalInfo,
        addressInfo,
        emergencyContactInfo,
        contractDetails,
        usedVehicleAddendum,
        additionalDriver,
        cancellationDetails,
        testDriveDetails,
        splendInitiatives,
        uberCAPDetails
    };

    // Used to avoid code repetition, as in multiple (more or less)
    // identical calls of setState functions for the 3 states.
    // When a state is to be updated, provide the state name as param
    // and the set state function is picked automatically, to which 
    // only the params have to be provided.
    const stateToSetStateMapping: { [key in UserFormStates]: React.SetStateAction<any> } = {
        driverDetails: setDriverDetails,
        carInfo: setCarInfo,
        personalInfo: setPersonalInfo,
        addressInfo: setAddressInfo,
        emergencyContactInfo: setEmergencyContactInfo,
        contractDetails: setContractDetails,
        usedVehicleAddendum: setUsedVehicleAddenumInfo,
        additionalDriver: setAdditionalDriver,
        cancellationDetails: setCancellationDetails,
        testDriveDetails: setTestDriveDetails,
        splendInitiatives: setSplendInitiatives,
        uberCAPDetails: setUberCAPDetails
    };

    // Used to pick automatically the initialisation object for a
    // specific state. Somehow mimics a switch case, but help to
    // reduce code duplication (not making multiple identical calls).
    const stateToInitStateObjMap: { [key in UserFormStates]: object } = {
        driverDetails: driverDetailsInit,
        carInfo: carInfoInit,
        personalInfo: personalInfoInit,
        addressInfo: addressInfoInit,
        emergencyContactInfo: emergencyContactInfoInit,
        contractDetails: contractDetailsInit,
        usedVehicleAddendum: usedVehicleAddendumInit,
        additionalDriver: additionalDriverInit,
        cancellationDetails: cancellationDetailsInit,
        testDriveDetails: testDriveDetailsInit,
        splendInitiatives: splendInitiativesInit,
        uberCAPDetails: uberCAPDetailsInit
    };

    // Used to update one of the states of the context.
    // Can update the entire object or only some targeted
    // fields and keeping the rest of them unchanged.
    const updateState = (
        stateName: UserFormStates,
        updatedState: formTypes,
    ) => stateToSetStateMapping[stateName]((prevState: object) => ({ ...prevState, ...updatedState }));

    // Used to reset one of the states of the context.
    // The new value will be the default object (used also at
    // the initialisation stage).
    const resetState = (stateName: UserFormStates) => stateToSetStateMapping[stateName](stateToInitStateObjMap[stateName]);

    const contextValue: UserJourneyValue = {
        //useCallback is used to avoid unnecessary re-renders of child components that depend on that function 
        //as a prop. Without useCallback there will be an error as the state will try and update each time a 
        //component is rendered.
        formState,
        updateFormState: useCallback(
            (stateName: UserFormStates, updatedFormState: object) => updateState(stateName, updatedFormState),
            []
        ),
        resetFormState: useCallback(
            (stateName: UserFormStates) => (resetState(stateName)),
            []
        ),
    };

    return <UserJourney.Provider value={contextValue}>{children}</UserJourney.Provider>;
};


export type { SplendInitiatives, EmergencyContactInfo, AddressInfo, ContractDetails, CarInfo, PersonalInfo, DriverDetails, UsedVehicleAddendumDetails };
