import React, {CSSProperties, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

import useServiceHook from "@hooks/useServiceHook";
import {SubscriptionService} from "@services/index";

import SubscriptionsListPanel from '@components/panels/SubscriptionsList/SubscriptionsList';
import {
    flexiOwnPlanNames,
    flexiOwnUsedPlanNames,
    ravCreditHirePlanNames,
    ravShareCoverPlanNames,
    ravShortTermPlanNames,
    SubscriptionPlan
} from "@utils/constants/products";
import {NavBar} from "@components/navigation/NavBar/NavBar";
import {ISubscriptionSummaryItem} from "@components/cards/SubscriptionSummary/SubscriptionSummary";
import CircularSpinner from "@components/spinners/Circular/Circular";
import {InfoBox, WarningBox} from '@components/cards/messageBox';
import {UserFormStates, useUserJourneyContext} from "@context/UserJourneyContext";
import breadcrumbObject from "SmartOpsHome/breadcrumbObject";


export default function SubscriptionsList() {
    const navigate = useNavigate();
    const { custId, country } = useParams();
    const { updateFormState } = useUserJourneyContext();

    
    // loading and error SHALL NOT be true in the same time
    const [loading, error, errMessage, results] = useServiceHook(SubscriptionService.getSubscriptions, [custId, country]);

    const parseSubsPlan = (subs: ISubscriptionSummaryItem[]) => {  // assign standard plan name to each subscription item
        return subs.map((subs: ISubscriptionSummaryItem) => {
            if (flexiOwnPlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.flexiOwn; }
            else if (flexiOwnUsedPlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.flexiOwnUsed; }
            else if (ravCreditHirePlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.ravCreditHire; }
            else if (ravShareCoverPlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.ravShareCover; }
            else if (ravShortTermPlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.ravShortTerm; }
            else { subs.plan = SubscriptionPlan.flexi; }
            return subs;
        });
    };

    const subscriptions = loading === false ? parseSubsPlan(results) : [];

    const allInactive = subscriptions.every(subs => subs.status === 'Inactive');

    useEffect(() => {
        updateFormState(UserFormStates.driverDetails, { plan: 'Uber CAP' });
        breadcrumbObject['Uber CAP']['Uber CAP Start Time'] = new Date();
    }, [results]);

    const selectSubscriptionLabelStyle: CSSProperties = {
        margin: '0',  // set to 0 to mitigate p tag's default margin value
        padding: '2vh 0 2vh 2.5vw',
        color: '#2C2A2D',
        fontWeight: '700',
        borderBottom: '1px solid #C4C4C4'
    };

    const isClickable = (subs: any): boolean => {
        return subs.status !== "Inactive"
    }


    return (<>
        <NavBar pageTitle="Uber CAP" />
        <p style={selectSubscriptionLabelStyle}>
            Subscription history
        </p>
        {loading === true
            ? <LoadingSpinner />
            : subscriptions.length > 0
                ? <>
                    <SubscriptionsListPanel
                        keyName='uber-cap-subscriptions'
                        items={subscriptions.map((subs: ISubscriptionSummaryItem) => {
                            return {
                                subsId: subs.subsId,
                                startDate: subs.startDate,
                                carDetails: subs.carDetails,
                                registration: subs.registration,
                                plan: subs.plan,
                                status: subs.status,
                                vehicleId: subs.vehicleId,
                                noticeGiven: subs.noticeGiven,
                                //If the subscription is not Inactive then the user is redirected from #/cancellation/{country}/{customerId} to 
                                //#/cancellation/{customerId}/{subsId}/{vehicleId} where the cancellation form is entered
                                onClickFn: isClickable(subs)
                                    ? () => {
                                        updateFormState(UserFormStates.uberCAPDetails, {cancellationPlanType: subs.plan})

                                        //todo other plans should not get here
                                        const basePath = `${subs.subsId}`;
                                        const planPath = subs.plan === 'Flexi' ? 'flexi' : 'flexi-own';
                                        navigate(`${basePath}/${planPath}`);


                                    }
                                    : undefined
                            }
                        })}
                    />
                    <div style={{ margin: '1.5vh 3.5vw' }}>
                        {allInactive && <WarningBox message='Please note that CAP savings can only be applied to active subscriptions' />}
                    </div>
                </>
                : <NoSubscriptionsInfoNote />
        }
    </>);
};

const LoadingSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
        <CircularSpinner />
    </div>
);


const NoSubscriptionsInfoNote = () => (
    <div style={{ margin: '1.5vh 3.5vw' }}>
        <InfoBox message='Please note that the customer does not have any subscriptions on their record' />
    </div>
);