import React from "react";


interface IMessageBox {
    message: string|React.ReactNode
    icon: string
    bgColor: string
    testId?: string
};


export default function MessageBox({ message, icon, bgColor, testId }: IMessageBox) {
    const styles = {
        justifyContent: 'center',
        display: 'flex',
        gap: '1.5vw',
        padding: '.8vh',
        background: bgColor,
        border: '1px solid #C4C4C4',
        borderRadius: '5px',
    };

    return (
        <div style={styles} data-testid={testId || 'messageBoxTestId'}>
            <img src={icon} alt={'msg-box-icon-src'} data-testid='messageBoxTestId-img' />
            <span>{message}</span>
        </div>
    );
};
