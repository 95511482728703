import React, {PropsWithChildren, useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';

import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate} from "@azure/msal-react";
import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "@utils/constants/msalConfig";

import {HashRouter as Router, Route, Routes} from "react-router-dom";

import SplendBanner from '@assets/images/splend_banner.png';
import LandingView from '@views/LandingView/LandingView';

import SmartOpsHomePage from '@views/SmartOpsHomePage/SmartOpsHomePage';
import CustomerMenu from "@views/menus/CustomerMenu";
import BillingAndPaymentsMenu from '@views/menus/BillingAndPayments';
import PHSubsList from '@views/PaymentHoliday/SubscriptionsList';
import OnboardingSubsList from '@views/journeys/OnboardingSubscriptionsList';
import PaymentHolidayOverview from 'views/PaymentHoliday/PaymentHolidayOverview';
import CustomerDetailsView from '@views/CustomerDetails';
import AutoLogoutWarning from '@components/modal/info/AutoLogoutWarning';

import SettingsMenu from '@views/menus/SettingsMenu';
import SrvErrorCtxProvider from 'context/ServerErrorContext';
import OnboardingMenuView from '@views/menus/OnboardingMenu';
import OnboardingForm from '@views/journeys/OnboardingForm';
import {UserJourneyProvider} from 'context/UserJourneyContext';
import OnboardingResult from '@views/journeys/OnboardingResult';
import SubscriptionDetails from '@views/RAV/SubscriptionDetails';
import VehicleAssignment from '@views/journeys/VehicleAssignment';
import UpdateCustomerDetailsMenu from '@views/menus/UpdateCustomerDetailsMenu';
import AdditionalDriverSubscriptionsList from '@views/AdditionalDriver/AdditionalDriverSubscriptionList';
import AdditionalDriverInfo from '@views/AdditionalDriver/AdditionalDriverInfo';
import AdditionalDriverPersonalDetails from '@views/AdditionalDriver/AdditionalDriverPersonalDetails';
import DriverDocuments from '@views/AdditionalDriver/DriverDocuments';
import AdditionalDriverResult from '@views/AdditionalDriver/AdditionalDriverResult';
import * as Sentry from "@sentry/react";
import {EnvTag, getEnvTag} from '@utils/AppEnv';
import CancellationSubscriptionsList from '@views/Cancellations/CancellationSubscriptionList';
import UberCAPSubscriptionList from '@views/UberCap/UberCAPSubscriptionList';
import CancellationVehicleDetails from '@views/Cancellations/CancellationVehicleDetails';
import CancellationReason from '@views/Cancellations/CancellationReason';
import CancellationCharges from '@views/Cancellations/CancellationCharges';
import CancellationReview from '@views/Cancellations/CancellationReview';
import CancellationPersonalDetails from '@views/Cancellations/CancellationPersonalDetails';
import CancellationAddressDetails from '@views/Cancellations/CancellationAddressDetails';
import CancellationConfirmation from '@views/Cancellations/CancellationResult';
import TestDriveVehicleAssignment from '@views/TestDrive/TestDriveVehicleAssignment';
import TestDrivePersonalDetails from '@views/TestDrive/TestDrivePersonalDetails';
import TestDriveEndDetails from '@views/TestDrive/TestDriveEndDetails';
import TestDriveDetails from '@views/TestDrive/TestDriveDetails';
import TestDriveHistoryList from '@views/TestDrive/TestDriveHistoryList';
import TestDriveAddressDetails from '@views/TestDrive/TestDriveAddress';
import TestDriveDriverLicence from '@views/TestDrive/TestDriveLicence';
import TestDriveStartConfirmation from '@views/TestDrive/TestDriveStartConfirmation';
import TestDriveEndConfirmation from '@views/TestDrive/TestDriveEndConfirmation';
import UberCAPFlexAmortisation from '../views/UberCap/UberCAPFlexiAmortisation';
import UberCAPReview from '@views/UberCap/UberCAPReview';
import UberCAPDetailsReview from '@views/UberCap/UberCAPDetailsReview';

// Obtain instance of MSAL to later provide as input to create the React context
// that will store all authentication related data that is managed by the MSAL library.
export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    // We are expecting only one active login per app
    msalInstance.setActiveAccount(accounts[0]);
}

// This callback has to be re-registered each time a login attempt is made
msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        msalInstance.setActiveAccount(payload.account);
        sessionStorage.removeItem('paymentholidayuseraccess')
    }
});

Sentry.init({
  dsn: getEnvTag() === EnvTag.Production ? "https://4e039c7616699aac35c8185ea4183ef3@o200972.ingest.sentry.io/4506439744487424" : "https://aa6e5129f8e8a0cac31a0bcd1841f35e@o200972.ingest.sentry.io/4506383201075200",
  environment: getEnvTag() === EnvTag.Production ? "Production": "Development",
  integrations: [new Sentry.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 0.4,

  // chosen events to not send to sentry. These are either not useful or covered by AWS
  beforeBreadcrumb(breadcrumb:any) {
    const excludedCategories = ["navigation", "console", "fetch", "xhr"]
    return excludedCategories.includes(breadcrumb.category) ? null : breadcrumb;
  },
});

// TODO: Investigate whether it is possible to perform the MSAL updates above
// using a React hook.


/**
 * Main component to define the routes, which, depends
 * on the root path to render the main Smart Ops home
 * page with the customer search/selection panel.
 */
const App = () => {
    // We need to handle redirect callbacks,
    // as the user can be redirected here after logging in/out from other pages.
    msalInstance.handleRedirectPromise();

    // Each path element is wrapped in the LoggedIn component
    // to protect the element from unauthorized access.
    // There is no path defined for a login screen because
    // that is automatically displayed by the LoggedIn component
    // when authentication is missing/failed.

    // SrvErrorCtxProvider shares an API to all wrapped components
    // to set/reset the error state based on failed server requests.
    // The API is shared via a hook (useAPIError) for consistency.
    // Currently a failed requests is everything that comes back
    // with a http status code greater or equal to 400, but also
    // timeouts.

    // The RAVJourneyProvider is used to store state information
    // as the user progresses through the RAV Onboarding journey.
    // In the future we may want to use a separate <Routes> path
    // so the Context is provided to only the RAV Journey.
    return (
        <Router>
            <MsalProvider instance={msalInstance}>
                <SrvErrorCtxProvider>
                    <UserJourneyProvider>
                        <Routes>
                            <Route path='*' element={<LoggedIn><AutoLogout><SmartOpsHomePage /></AutoLogout></LoggedIn>} />
                            <Route path='/customer/:country/:custId' element={<LoggedIn><AutoLogout><CustomerDetailsView /></AutoLogout></LoggedIn>} >
                                <Route path='overview' element={<LoggedIn><AutoLogout><CustomerMenu /></AutoLogout></LoggedIn>} />
                                <Route path='payments-menu' element={<LoggedIn><AutoLogout><BillingAndPaymentsMenu /></AutoLogout></LoggedIn>} />
                                <Route path='onboarding' element={<LoggedIn><AutoLogout><OnboardingMenuView /></AutoLogout></LoggedIn>} />
                                <Route path='update-customer-details' element={<LoggedIn><AutoLogout><UpdateCustomerDetailsMenu /></AutoLogout></LoggedIn>} />
                            </Route>
                            <Route path='/update-customer-details/:country/:custId'>
                                <Route index element={<LoggedIn><AutoLogout /><AdditionalDriverSubscriptionsList /></LoggedIn>} />
                                <Route path='info' element={<LoggedIn><AutoLogout><AdditionalDriverInfo /></AutoLogout></LoggedIn>} />
                                <Route path='details' element={<LoggedIn><AutoLogout><AdditionalDriverPersonalDetails /></AutoLogout></LoggedIn>} />
                                <Route path='documents' element={<LoggedIn><AutoLogout><DriverDocuments /></AutoLogout></LoggedIn>} />
                                <Route path='confirmation' element={<LoggedIn><AutoLogout><AdditionalDriverResult /></AutoLogout></LoggedIn>} />
                            </Route>
                            <Route path='/payment-holiday/:country/:custId'>
                                <Route index element={<LoggedIn><AutoLogout /><PHSubsList /></LoggedIn>} />
                                <Route path=':subsId' element={<LoggedIn><AutoLogout><PaymentHolidayOverview /></AutoLogout></LoggedIn>} />
                            </Route>
                            <Route path='/onboarding/:country/:custId'>
                                <Route index element={<LoggedIn><AutoLogout><OnboardingSubsList /></AutoLogout></LoggedIn>} />
                                <Route path=':subsId' element={<LoggedIn><AutoLogout><SubscriptionDetails /></AutoLogout></LoggedIn>} />
                                <Route path='vehicle-assignment' element={<LoggedIn><AutoLogout><VehicleAssignment /></AutoLogout></LoggedIn>} />
                                <Route path='result' element={<LoggedIn><AutoLogout><OnboardingResult /></AutoLogout></LoggedIn>} ></Route>
                            </Route>
                            <Route path='/onboarding/details'>
                                <Route index element={<LoggedIn><AutoLogout><OnboardingForm /></AutoLogout></LoggedIn>} />
                            </Route>
                            <Route path='/cancellation/:country/:custId'>
                                <Route index element={<LoggedIn><AutoLogout><CancellationSubscriptionsList /></AutoLogout></LoggedIn>} />
                                <Route path=':subsId/:vehicleId' element={<LoggedIn><AutoLogout><CancellationVehicleDetails /></AutoLogout></LoggedIn>} />
                                <Route path='personal' element={<LoggedIn><AutoLogout><CancellationPersonalDetails /></AutoLogout></LoggedIn>} />
                                <Route path='address' element={<LoggedIn><AutoLogout><CancellationAddressDetails /></AutoLogout></LoggedIn>} />
                                <Route path='reason' element={<LoggedIn><AutoLogout><CancellationReason /></AutoLogout></LoggedIn>} />
                                <Route path='charges' element={<LoggedIn><AutoLogout><CancellationCharges /></AutoLogout></LoggedIn>} />
                                <Route path='review' element={<LoggedIn><AutoLogout><CancellationReview /></AutoLogout></LoggedIn>} />
                                <Route path='confirmation' element={<LoggedIn><AutoLogout><CancellationConfirmation /></AutoLogout></LoggedIn>} /> 
                            </Route>

                            <Route path='/uber-cap/:country/:custId'>
                                <Route index element={<LoggedIn><AutoLogout><UberCAPSubscriptionList /></AutoLogout></LoggedIn>} />
                                <Route path=':subsId/flexi-own' element={<LoggedIn><AutoLogout><UberCAPFlexAmortisation /></AutoLogout></LoggedIn>} />
                                <Route path='front-review' element={<LoggedIn><AutoLogout><UberCAPReview /></AutoLogout></LoggedIn>} />
                                <Route path='details-review' element={<LoggedIn><AutoLogout><UberCAPDetailsReview /></AutoLogout></LoggedIn>} />
                            </Route>



                            <Route path='/test-drive/:country/:custId'>
                              <Route index element={<LoggedIn><AutoLogout><TestDriveHistoryList /></AutoLogout></LoggedIn>} />
                                <Route path='vehicle' element={<LoggedIn><AutoLogout><TestDriveVehicleAssignment /></AutoLogout></LoggedIn>} />
                                <Route path='personal' element={<LoggedIn><AutoLogout><TestDrivePersonalDetails/></AutoLogout></LoggedIn>} />
                                <Route path='address' element={<LoggedIn><AutoLogout><TestDriveAddressDetails/></AutoLogout></LoggedIn>} />
                                <Route path='licence' element={<LoggedIn><AutoLogout><TestDriveDriverLicence /></AutoLogout></LoggedIn>} />
                                <Route path='start' element={<LoggedIn><AutoLogout><TestDriveDetails/></AutoLogout></LoggedIn>} />
                                <Route path='end' element={<LoggedIn><AutoLogout><TestDriveEndDetails/></AutoLogout></LoggedIn>} />
                                <Route path='book' element={<LoggedIn><AutoLogout><TestDriveStartConfirmation/></AutoLogout></LoggedIn>} />
                                <Route path='end-booking' element={<LoggedIn><AutoLogout><TestDriveEndConfirmation/></AutoLogout></LoggedIn>} />
                            </Route>
                            <Route path='/settings' element={<LoggedIn><AutoLogout><SettingsMenu /></AutoLogout></LoggedIn>} />
                        </Routes>
                    </UserJourneyProvider>
                </SrvErrorCtxProvider>
            </MsalProvider >
        </Router >
    );
};

/**
 * Protects the route element by rendering only when there is an
 * authenticated user. Otherwise, display the login landing page.
 *
 * If React Router best practice changes to have nested routes
 * defined in the top level component of each nested routes group,
 * then, this component should be moved out to separate component
 * so that it can be re-used by those top level components.
 *
 * @params {ReactNode} props - component properties
 * @return React fragment of route element or login page
 */
const LoggedIn = (props: PropsWithChildren) => {
    const loginMsg = 'Welcome to Splend Smart Ops';
    return (
        <React.Fragment>
            <AuthenticatedTemplate>
                {props.children}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <LandingView splendBanner={SplendBanner} text={loginMsg} />
            </UnauthenticatedTemplate>
        </React.Fragment>
    );
};

/**
 * Adds  auto-logout functionality to a route.
 * Logout message will be displayed after 10 minutes (1 second x 60 x 10).
 * Apply to routes that require login:
 *  <LoggedIn><AutoLogout><CustomerDetailsView /></AutoLogout></LoggedIn>
 * Can be omitted from  routes where we don't want to timeout.
 *
*/
const AutoLogout = (props: PropsWithChildren) => {
    const [hasTimeElapsed, setHasTimeElapsed] = useState(false);
    const [valueFromChild, setValueFromChild] = useState(false);
  
    let timeoutId: NodeJS.Timeout;
  
    useEffect(() => {
      // Listen for user activity events
      const handleUserActivity = () => {
        resetTimer();
      };
  
      // Attach event listeners for user activity (e.g., click or keypress)
      window.addEventListener('click', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);
  
      // Set up the initial timer
      resetTimer();
  
      return () => {
        // Clean up event listeners
        window.removeEventListener('click', handleUserActivity);
        window.removeEventListener('keypress', handleUserActivity);
        clearTimeout(timeoutId);
      };
    }, [hasTimeElapsed]);

    const resetTimer = () => {
        if (!hasTimeElapsed) {
          clearTimeout(timeoutId); // Clear the previous timeout
          timeoutId = setTimeout(() => {
            setHasTimeElapsed(true);
          }, 1000 * 60 * 10);
        }
      };
  
    const handleValueFromChild = (value: boolean) => {
      setValueFromChild(value);
      if (!value) {
        setHasTimeElapsed(false);
      }
    };
  
    return (
      <>
        {hasTimeElapsed && (
          <AutoLogoutWarning sendValueToParent={handleValueFromChild} />
        )}
        {props.children}
      </>
    );
  };
  
createRoot(document.getElementById('root') || document.createElement('div')).render(
    <App />
);
