import React from 'react';

import Label from '@components/inputs/text/Label';
import {useNavigate} from 'react-router';
import {NavBar} from '@components/navigation/NavBar/NavBar';
import Button from "../../components/buttons/Button/Button";
import {useUserJourneyContext} from "../../context/UserJourneyContext";
import useServiceHook from "../../hooks/useServiceHook";
import SubscriptionService from "../../services/subscription/SubscriptionService";
import EllipticalSpinner from "../../components/spinners/Elliptical/Elliptical";


export default function UberCAPReview() {

    const navigate = useNavigate();

    const { formState, updateFormState } = useUserJourneyContext();
    const {  uberCAPDetails } = formState;

    const renderDetailSection = (label: any, value: any) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
                <Label text={label} styleCfg={{ bold: true }} />
            </div>
            <p style={{ margin: 0 }} >{value || ''}</p>
        </div>
    );

    const buttonContainerStyle = {
        marginTop: '5vh',
    };

    const [subsLoading, subsError, subsErrMsg, subsDetails] = useServiceHook(
        SubscriptionService.getSubscriptionById,
        ['4992872000151887022', 'GB'] //todo just added as a test

    );



    return (<>
            <div style={{margin: '2.5vh 0vw'}}>
                <NavBar pageTitle="Uber CAP"/>

                <div style={{margin: '2.5vh  3.5vw'}}>
                    <p><strong>Step 2</strong></p>

                    <p>Please review the following details with the customer:</p>
                </div>

                <div style={{margin: '2.5vh 3.5vw'}}>
                    {renderDetailSection('Total CAP savings', uberCAPDetails.totalSavings)}
                    {renderDetailSection('Weekly EV Assistance (£)', '75')}
                    {renderDetailSection('Weekly Payment Amount (£)', '1')}

                    {subsLoading !== false ? <EllipticalSpinner /> : subsDetails.rentalAgreement}

                    {renderDetailSection('Weekly Payment Amount after CAP deduction(£)', '1')}
                    {renderDetailSection('Weeks of discount (front-loaded)', '1')}

                    {renderDetailSection('Final week discount amount (£)', '1')}
                </div>

                <div style={{margin: '2.5vh 3.5vw'}}>
                    <p>
                        The customer will receive x weeks of <strong>front-loaded</strong> discount of
                        <strong>£75 per week</strong> in week y (week commencing <strong>z</strong>) they will receive
                        a further <strong>$a</strong> discount.
                    </p>
                    <p>
                        After this point their Total CAP saving amount of <strong>£b</strong> will be exhausted.
                    </p>
                </div>





            </div>


            <div style={buttonContainerStyle}>
            <Button label={"Next"} onClickFunc={() => navigate(`../details-review`)}></Button>
            </div>


        </>
    )
};
