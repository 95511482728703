import React, {useState} from 'react';

import Label from '@components/inputs/text/Label';
import {useNavigate} from 'react-router';
import {NavBar} from '@components/navigation/NavBar/NavBar';
import Button from "../../components/buttons/Button/Button";
import {useUserJourneyContext} from "../../context/UserJourneyContext";
import {textBoxStyle} from "../../utils/styles/textboxStyle";
import {WarningBox} from "../../components/cards/messageBox";
import {OnboardingService} from "../../services/journeys/Onboarding";
import {Country} from "../../utils/constants/localisation";
import {removeBeforeUnloadListener} from "../../utils/refreshCatcher";
import WarningModal from "../../components/modal/WarningModal";
import {useAPIError} from "../../context/ServerErrorContext";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";


export default function UberCAPDetailsReview() {

    const navigate = useNavigate();
    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const {formState, updateFormState} = useUserJourneyContext();
    const {uberCAPDetails} = formState;
    const [loading, setLoading] = useState<Boolean>(false);
    const { setErr, removeErr } = useAPIError();

    const renderDetailSection = (label: any, value: any) => (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>
                <Label text={label} styleCfg={{bold: true}}/>
            </div>
            <p style={{margin: 0}}>{value || ''}</p>
        </div>
    );

    const submitForSignature = async() => {

        setLoading(true)

        const payload = {
            prefill_data: {
                field_text_data: {
                    plan: 'testCAP'
                }
            }
        }


        OnboardingService.obtainCustomerSignUrl(
            payload,
            Country.GB, //todo
            { msalContext, isAuthenticated }
        ).then((resp) => {
            removeBeforeUnloadListener(); // end event listener at the end of the form
            // go to signing page
            window.location.replace(resp.sign_url);
            setLoading(false);
        }).catch((err) => {
            // Display an error modal - by acknowledging the error and clicking Ok btn, the user is sent to homepage
            setErr(
                true,
                {
                    msg: 'Error with onboarding.',
                    UI: <WarningModal message={"Something went wrong"} additionalInfo={`We’re sorry, we were unable to process your request.  
                    We will investigate the issue and in the meantime, please complete the form manually .`} onClickClose={() => { removeErr(); navigate('/'); }} />
                }
            );
            setLoading(false);
        });
    }

    const buttonContainerStyle = {
        marginTop: '5vh',
    };


    return (<>
            <div style={{margin: '2.5vh 0vw'}}>
                <NavBar pageTitle="Uber CAP"/>

                <div style={{margin: '2.5vh  3.5vw'}}>
                    <p><strong>Step 3</strong></p>
                    <p>Please ensure that the customer's name exactly matches their driver's licence:</p>
                </div>


                <div style={{margin: '2.5vh 3.5vw'}}>

                    <Label text={'First Name'} requiredFieldInd={true}/>
                    <input
                        style={textBoxStyle}
                        name='firstName'
                        required={true}
                        value={'todo'}
                        //      onChange={}
                    />


                    <Label text={'Last Name'} requiredFieldInd={true}/>
                    <input
                        style={textBoxStyle}
                        name='firstName'
                        required={true}
                        value={'todo'}
                        //        onChange={}
                    />


                    <div style={{margin: '1.5vh 0vw'}}>
                        <WarningBox
                            message='Please note that changes will be reflected on the customer Zoho contact and Zoho subscription record'/>
                    </div>

                    <div style={{margin: '1.5vh 0vw'}}>
                        <p>Please ensure that the customer's name exactly matches their driver's licence:</p>

                        {renderDetailSection('Make', 'Ferrari')}
                        {renderDetailSection('Model', 'Outlander')}
                        {renderDetailSection('Vehicle Registration', 'XRY 213S')}

                    </div>

                    <div style={{margin: '1.5vh 0vw'}}>
                        <p>On clicking 'Finish' the system will generate a copy of the UberClean Air Fund Confirmation
                            letter which the customer will
                            need to sign to complete the Uber CAP application.</p>
                    </div>

                </div>
            </div>


            <div style={buttonContainerStyle}>
                <Button label={"Submit"} onClickFunc={() => submitForSignature()}></Button>
            </div>
        </>
    )
};
