import React, {useState} from "react";

import Label from '@components/inputs/text/Label';
import {UserFormStates, useUserJourneyContext} from '@context/UserJourneyContext';
import {useNavigate} from 'react-router';
import InfoBox from '@components/cards/messageBox/InfoBox';
import {NavBar} from '@components/navigation/NavBar/NavBar';
import {textBoxStyle} from "../../utils/styles/textboxStyle";
import DropDownMenu, {IChoice} from "../../components/inputs/DropDownMenu";
import Button from "../../components/buttons/Button/Button";

export default function UberCAPFlexAmortisation() {

    const { formState, updateFormState } = useUserJourneyContext();
    const {  uberCAPDetails } = formState;


    const dropDownPleaseSelect: IChoice = { label: '- please select -', value: '', enabled: false };
    const navigate = useNavigate();

    const scheduleOptions = [
        { label: 'Front-loaded', value: 'front', enabled: true },
        { label: 'Evenly amortised', value: 'even', enabled: true }
    ];

    const buttonContainerStyle = {
        marginTop: '5vh',
    };

    const [selectedSchedule, setSelectedSchedule] = useState('');
    const [savingValue, setValue] = useState('');

    // only allow numeric in the Cap saving input
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value.replace(/[^0-9]/g, '');
      setValue(newValue);
    };

    // update the context with the schedule type
    const handleScheduleChange =  (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedSchedule(e.currentTarget.value);
    };

    //navigate to the correct component based on the selected schedule
    const nextClicked = () => {
        //validate?

        updateFormState(UserFormStates.uberCAPDetails, {
            plan: 'flexi',
            totalSavings: Number(savingValue),
            schedule: selectedSchedule

        });

        if (selectedSchedule === 'front') {
            navigate('../front-review');
        } else {
            navigate('../even-review');
        }
    }

    return (<>
            <div style={{margin: '2.5vh 0vw'}}>
                <NavBar pageTitle="Uber CAP"/>

                <div style={{margin: '2.5vh  3.5vw'}}>
                    <p><strong>Step 1</strong></p>
                </div>

                <div style={{margin: '2.5vh  3.5vw'}}>
                    <Label text={'Total CAP savings (£)'} requiredFieldInd={true}/>
                    <input
                        style={textBoxStyle}
                        name='totalSavings'
                        required={true}
                        value={savingValue}
                        onChange={handleChange}
                    />
                </div>

                <div style={{margin: '1.5vh 3.5vw'}}>
                    <InfoBox
                        message={
                            <>
                                Please validate the customer's Total savings using the
                                <a
                                    href='https://docs.google.com/spreadsheets/d/1GeGg8x-Yh1VY-mNzIEUn_gjalipKluMqPW7CEVKSGV0/edit'
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Uber CAP savings spreadsheet
                                </a>.
                            </>
                        }
                    />
                </div>

                <>
                    <div style={{margin: '2.5vh 3.5vw'}}>
                        <Label
                            text={'Amoritisation schedule'}
                            styleCfg={{largeFont: false, bold: false}}
                            requiredFieldInd={true}
                        />
                        <DropDownMenu
                            menuName={'schedule'}
                            defaultVal={dropDownPleaseSelect}
                            required={true}
                            choices={[dropDownPleaseSelect].concat(scheduleOptions)}
                            onSelect={handleScheduleChange}


                        />
                    </div>
                </>


                <div style={{margin: '1.5vh 3.5vw'}}>
                    <InfoBox
                        message={
                            <>
                                A <strong>front-loaded schedule</strong> means the customer will receive
                                a <strong>£75</strong> per week discount on their subscription fee until their CAP
                                savings have been exhausted.
                                <br></br>
                                <br></br>
                                An <strong>evenly amortised schedule</strong> means the customer’s CAP savings will
                                be <strong>spread evenly across the length of their subscription</strong>
                            </>
                        }
                />
                </div>
            </div>


            <div style={buttonContainerStyle}>
                <Button label={"Next"} onClickFunc={nextClicked}></Button>
            </div>


        </>
    )
};
